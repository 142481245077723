import { Carousel } from "react-bootstrap";
import React from "react";

export default function Banner({ photos = [] }) {
  return (
    <>
      {Array.isArray(photos) ? (
        <Carousel indicators={false} controls={false}>
          {photos.map((image) => {
            return (
              <Carousel.Item>
                <div
                  id="banner_photo"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: "center",
                  }}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      ) : null}
    </>
  );
}
