import React from 'react';

import i2 from "../../Photos/gallery/2.JPG"
import i3 from "../../Photos/gallery/3.JPG"
import i4 from "../../Photos/gallery/4.JPG"
import i5 from "../../Photos/gallery/5.JPG"
import i6 from "../../Photos/gallery/6.JPG"
import i7 from "../../Photos/gallery/7.JPG"
import i8 from "../../Photos/gallery/8.JPG"
import i9 from "../../Photos/gallery/9.JPG"


import { Stack } from 'react-bootstrap';

import './Galeria.css'


export default function Galeria() {

    const itemData = [
        {
            img1:i2,
            img2:i3,

        },
        {
            img1:i4,
            img2:i5,
        },
        {
            img1:i6,
            img2:i7,
        },
        {
            img1:i8,
            img2:i9,
        },
    ];


    return (
    <div className='pb-3'>
        <Stack direction="horizontal" gap={0} className='myStack' >
            {itemData.map((item) => (
                <div>
                    <img src={item.img1} style={{width:"100%", height:"auto"}}></img>
                    <img src={item.img2} style={{width:"100%", height:"auto"}}></img>
                </div>
            ))}
        </Stack>
    </div>
    );

}

